.topbar {
  .navbar {
    .navbar-brand {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1.1rem;
      color: #05345e;
      font-family: 'Bebas Neue', sans-serif;

      img {
        width: 50px;
      }
    }

    .navbar-collapse {
      .center-nav {
        display: flex;
        justify-content: center;
        gap: 1rem;
        flex: 1;

        a.nav-link {
          font-family: 'Quicksand';
          font-weight: 900;
          text-transform: unset;
          font-size: 1rem;
          color: #14545d;
          position: relative;

          &.active {
            &::after {
              position: absolute;
              content: '';
              background-color: #ffcd03;
              border-radius: 10px 10px 0 0;
              width: 70%;
              height: 7px;
              bottom: -18px;
              left: 0;
              right: 0;
              margin: auto;
            }
          }
        }
      }

      .end-nav {
        flex: 2;
        gap: 1rem;
        display: flex;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
            height: 40px;
        }
      }
    }
  }
}
