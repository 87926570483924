@font-face {
  font-family: 'Bebas Neue';
  src: url('assets/fonts/BebasNeue-Regular.ttf');
}

@font-face {
  font-family: 'Quicksand';
  font-weight: 400;
  src: url('assets/fonts/Quicksand-Regular.ttf');
}

@font-face {
  font-family: 'Quicksand';
  font-weight: 600;
  src: url('assets/fonts/Quicksand-Medium.ttf');
}

@font-face {
  font-family: 'Quicksand';
  font-weight: 800;
  src: url('assets/fonts/Quicksand-SemiBold.ttf');
}

@font-face {
  font-family: 'Quicksand';
  font-weight: 900;
  src: url('assets/fonts/Quicksand-Bold.ttf');
}

*,
body {
  font-family: 'Quicksand', sans-serif;
}
