.home-main {
  height: auto;
  background-image: url('/assets/images/banner.png');
  background-size: cover;
  background-position: bottom;
  padding: 4rem 0 0;

  .left-container {
    .header {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 70px;
      }

      h2 {
        margin: 0;
        padding: 0;
        color: #05345e;
        font-size: 1.5rem;
        font-family: 'Bebas Neue', sans-serif;

        span {
          margin: 0;
          padding: 0;
          display: block;
          color: #05345e;
          font-size: 1.5rem;
          font-weight: bold;
          font-family: "Quicksand";
          text-transform: uppercase;
        }
      }
    }

    .main-image {
      margin-top: 10px;
      position: relative;
      text-align: center;

      img.image {
        width: 100%;
        max-width: 690px;
      }
      
      @media (min-width: 769px) {
        img.image {
          margin-left: -50px;
          margin-bottom: 20px; 
        }
      }

      .date {
        position: absolute;
        width: 24rem;
        bottom: -4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        p {
          margin: 0;
          padding: 0;
          width: 90%;
          font-size: 13px;
          color: #05345e;
          font-weight: 900;
          text-align: center;
          font-family: 'Quicksand';
        }

        p.white-container {
          gap: 1rem;
          width: 100%;
          display: flex;
          padding: 1rem;
          align-items: center;
          border-radius: 14px;
          justify-content: center;
          background-color: #fff;

          svg {
            width: 1rem;
          }

          b {
            display: block;
          }

          span {
            text-align: center;
          }
        }
      }
    }
  }

  .right-container {
    .form-register {
      background-color: #fff;
      border-radius: 7px;
      padding: 3rem 1.5rem;
      position: relative;
      bottom: -4rem;

      h2 {
        color: #05345e;
        line-height: 1;
        font-family: 'Bebas Neue', sans-serif;
        margin-bottom: 0.5rem;
      }

      p {
        font-family: 'Quicksand';
        font-weight: 900;
        font-size: 14px;
      }

      .separator {
        border-top: dashed 3px #c1d8db;
        height: 3px;
        width: 100%;
        margin-bottom: 1rem;
      }

      .submit-container {
        display: flex;
        gap: 1rem;
        align-items: center;

        button {
          flex: 0 0 auto;
        }

        .btn.btn-primary {
          background-color: #14545d;
          font-size: 14px;
          font-weight: 900;
          padding: 0.5rem 1rem;
        }

        p {
          font-size: 14px;
          margin: 0;
        }
      }
    }
  }
}

.countdown {
  background-color: #f2f4f7;
  padding: 2rem 0;

  .countdown-container {
    display: flex;
    gap: 2rem;
    align-items: center;

    span {
      font-weight: 900;
      color: #14545d;
      margin: 0;
      width: 15rem;
    }

    .timer {
      display: flex;
      gap: 2rem;
      align-items: center;

      .comma {
        color: #82a6ac;
        font-size: 50px;
        font-weight: 900;
        margin: 0;
        line-height: 1;
      }

      .block {
        text-align: center;

        p {
          font-size: 50px;
          font-weight: 900;
          margin: 0;
          line-height: 1;

          &.days {
            color: #14545d;
          }

          &.hours {
            color: #218998;
          }

          &.minutes {
            color: #23b8cd;
          }

          &.seconds {
            color: #38cadf;
          }
        }

        span {
          color: #82a6ac;
          font-weight: 900;
          margin: 0;
        }
      }
    }
  }
}

.content {
  height: auto;
  background-image: url('/assets/images/second-background.png');
  background-size: cover;
  background-position: bottom;
  padding: 5rem 0;

  .content-image {
    display: flex;
    justify-content: flex-start;

    img {
      width: 90%;
      max-width: 100%;
    }
  }

  .content-text {
    text-align: center;

    h2 {
      width: 33rem;
      font-size: 3rem;
      color: #05345e;
      margin-left: auto;
      margin: 0 auto 1rem;
      font-family: 'Bebas Neue';
    }

    p {
      color: #2e2e2e;
      font-size: 14px;
      font-weight: 900;
    }
  }

  .panel-forum {
    text-align: center;
  
    h2 {
      width: 33rem;
      font-size: 3rem;
      color: #05345e;
      margin-left: auto;
      margin: 0 auto 1rem;
      font-family: 'Bebas Neue';
    }
  
    .list {
      gap: 1rem;
      display: flex;
      flex-wrap: nowrap;
  
      .data {
        flex: 1 1 100%;
        padding: 1rem;
        border-radius: 7px;
        background-color: #fff;
  
        h4 {
          font-size: 1rem;
          margin: 0 0 1rem;
          font-weight: bold;
          min-height: 57.6px;
        }
  
        p {
          font-size: 14px;
  
          br {
            display: block;
          }
        }
      }
    }
  
    @media (max-width: 768px) {
      .list {
        flex-wrap: wrap; 
      }
  
      .data {
        flex: 1 1 100%; 
      }
  
      h2 {
        width: 100%; 
        font-size: 2rem; 
      }
    }
  }
  
  
  .register-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    Button.btn {
      background-color: #14545d;
      font-size: 18px;
      font-weight: 900;
      padding: 0.5rem 1rem;
      border-color: #14545d;

      &.cancel {
        background-color: #e60a0a;
        border-color: #e60a0a;
      }

      &.clear {
        background-color: #fff;
        border: solid 2px #14545d;
        color: #14545d;
      }
    }
  }
}

.our-partners {
  height: auto;
  background-image: url('/assets/images/second-background1.png');
  background-size: cover;
  background-position: top;
  padding: 4rem 0;

  .partners-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      color: #05345e;
      font-family: 'Bebas Neue';
      font-size: 3rem;
      width: 33rem;
      margin-left: auto;
      margin-right: auto;
    }

    .partners {
      gap: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      img {
        height: 4rem;
        width: auto;
      }
    }
  }
}

.more-information {
  height: auto;
  background-image: url('/assets/images/second-background.png');
  background-size: cover;
  background-position: top;
  padding: 4rem 0;

  .information-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    h2 {
      color: #05345e;
      font-family: 'Bebas Neue';
      font-size: 3rem;
    }

    .final-form {
      background-color: #fff;
      border-radius: 7px;
      padding: 3rem 2rem;
      width: 100%;

      h4 {
        color: #05345e;
        font-family: 'Bebas Neue';
        font-size: 1.2rem;
      }

      .submit-container {
        display: flex;
        gap: 1rem;

        Button.btn {
          background-color: #14545d;
          font-size: 14px;
          font-weight: 900;
          padding: 0.5rem 1rem;
          border-color: #14545d;

          &.cancel {
            background-color: #e60a0a;
            border-color: #e60a0a;
          }

          &.clear {
            background-color: #fff;
            border: solid 2px #14545d;
            color: #14545d;
          }
        }
      }
    }
  }
}

.form-group {
  position: relative;

  label {
    font-family: 'Quicksand';
    font-weight: 900;
    font-size: 14px;
    position: absolute;
    color: #2e2e2e;
    top: -11px;
    left: 10px;
    background-color: #fff;
    line-height: 1;
    margin-bottom: 0;

    b {
      color: #ff0000;
    }
  }

  label.for-file {
    position: static;
  }

  input.form-control,
  select.form-select {
    margin-top: 1rem;
    font-family: 'Quicksand';
    font-weight: 800;
    font-size: 14px;

    &::placeholder {
      color: #d1d1d1;
      font-family: 'Quicksand';
      font-weight: 800;
      font-size: 14px;
    }
  }
}

.form-group-check {
  label {
    font-family: 'Quicksand';
    font-weight: 900;
    font-size: 14px;
    color: #2e2e2e;
    background-color: #fff;
    line-height: 1;
    margin-bottom: 0;

    b {
      color: #ff0000;
    }
  }

  .selection {
    .inline {
      display: inline-block;
      width: auto;
    }
  }
}

.form-file {
  display: flex;
  gap: 1rem;
  margin: 1rem 0 1rem 0;
  align-items: center;

  .form-label {
    position: static;
    color: #fff;
    background-color: #14545d;
    padding: 1rem;
    border-radius: 7px;
    cursor: pointer;
  }

  span {
    font-weight: 900;
    color: #c3d4d6;
    font-size: 14px;
  }

  input {
    display: none;
  }
}


@media (max-width: 768px) {
  .home-main {
    padding: 2rem 0 0;

    .left-container {
      .header {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        img {
          width: 50px;
        }

        h2 {
          font-size: 1.2rem;

          span {
            font-size: 1.2rem;
          }
        }
      }

      .main-image {
        margin-top: 10px;
        margin-bottom: 30px;

        img.image {
          max-width: 100%;
        }

        .date {
          width: 100%;
          bottom: rem;

          p {
            font-size: 12px;
          }

          p.white-container {
            margin-bottom: -5rem;
            padding: 0.5rem;
            font-size: 12px;

            svg {
              width: 0.8rem;
            }
          }
        }
      }
    }

    .right-container {
      .form-register {
        margin-top: 10rem;
        padding: 2rem 1rem;
        bottom: -2rem;

        h2 {
          font-size: 1.2rem;
        }

        p {
          font-size: 12px;
        }

        .separator {
          margin-bottom: 0.5rem;
        }

        .submit-container {
          button {
            font-size: 12px;
            padding: 0.3rem 0.8rem;
          }

          p {
            font-size: 12px;
          }
        }
      }
    }
  }

  .countdown {
    padding: 1rem 0;

    .countdown-container {
      padding-top: 2rem;
      flex-direction: column;
      align-items: center;

      span {
        width: auto;
      }

      .timer {
        flex-direction: row;
        gap: 1rem;

        .comma {
          font-size: 30px;
        }

        .block {
          p {
            font-size: 30px;
          }
        }
      }
    }
  }

  .content {
    padding: 3rem 0;

    .content-image {
      img {
        width: 100%;
      }
    }

    .content-text {
      h2 {
        width: 100%;
        font-size: 2rem;
      }

      p {
        font-size: 12px;
      }
    }

    .panel-forum {
      h2 {
        width: 100%;
        font-size: 2rem;
      }

      .list {
        .data {
          h4 {
            font-size: 0.8rem;
          }

          p {
            font-size: 12px;
          }
        }
      }
    }

    .register-button {
      Button.btn {
        font-size: 14px;
        padding: 0.3rem 0.8rem;
      }
    }
  }

  .form-group {
    position: relative;
    white-space: nowrap;
  
    label {
      font-family: 'Quicksand';
      font-weight: 900;
      font-size: 10px;
      position: absolute;
      color: #2e2e2e;
      top: -11px;
      left: 10px;
      background-color: #fff;
      line-height: 1;
      margin-bottom: 0;
  
      b {
        color: #ff0000;
      }
    }
  
    label.for-file {
      position: static;
    }
  
    input.form-control,
    select.form-select {
      margin-top: 1rem;
      font-family: 'Quicksand';
      font-weight: 800;
      font-size: 14px;
  
      &::placeholder {
        color: #d1d1d1;
        font-family: 'Quicksand';
        font-weight: 800;
        font-size: 14px;
      }
    }
  }

  .row > td {
    display: block;
    width: 100%;   
  }

  label.for-file {
    font-size: 15px;
    position: static;
  }

  .form-file {
    display: flex;
    gap: 1rem;
    margin: 1rem 0 0 0;
    align-items: center;
  
    .form-label {
      position: static;
      color: #fff;
      background-color: #14545d;
      padding: 1rem;
      border-radius: 7px;
      cursor: pointer;
    }
  
    span {
      font-weight: 900;
      color: #c3d4d6;
      font-size: 14px;
    }
  
    input {
      display: none;
    }
  }

  .our-partners {
    padding: 2rem 0;

    .partners-container {
      h2 {
        width: 100%;
        font-size: 2rem;
      }

      .partners {
        flex-wrap: wrap;

        img {
          height: 3rem;
        }
      }
    }
  }

  .more-information {
    padding: 2rem 0;

    .information-container {
      h2 {
        font-size: 2rem;
      }

      .final-form {
        padding: 2rem 1rem;

        h4 {
          font-size: 1rem;
        }

        .submit-container {
          Button.btn {
            font-size: 12px;
            padding: 0.3rem 0.8rem;
          }
        }
      }
    }
  }
}