.footer {
  background-color: #dcdcdc;
  padding: 4em 0;

  .phil-seal {
    img {
      width: 100%;
    }
  }

  .footer-list {
    h3 {
      color: #2e2e2e;
      font-size: 1.1rem;
      font-weight: 900;
      line-height: 1;
      margin-bottom: 1.4rem;
    }

    p {
      font-size: 14px;
      font-weight: 900;
      color: #2e2e2e;
    }

    ul {
      padding-left: 1rem;

      li {
        font-size: 14px;
        font-weight: 900;
        color: #2e2e2e;
      }
    }

    .icon {
        display: flex;
        gap: 5px;
        margin: 0 0 1rem;
        align-items: center;

        svg {
            width: 25px;
        }
    }
  }
}

footer {
    background-color: #272727;
    padding: 2rem 0;

    .footer-container {
        text-align: center;

        p {
            font-size: 16px;
            font-weight: 900;
            color: #fff;
            margin-bottom: 10px;
            line-height: 1;
        }
    }
}

.footer-list a {
  color: inherit; /* Inherits the color of the parent element */
  text-decoration: none; /* Removes the underline */
}

.footer-list a:hover,
.footer-list a:focus,
.footer-list a:active {
  color: inherit; /* Keeps the color the same on hover, focus, and active states */
  text-decoration: none; /* Keeps the underline removed on hover, focus, and active states */
  outline: none; /* Removes the outline that appears on focus */
}